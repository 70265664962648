.chart {
    position: relative;
    display: flex;
    min-height: 450px;
    opacity: 0;
    transition: opacity 0.5s ease;

    .chart-title {
        margin: 1rem 0 0;
        text-align: center; }

    &.show {
        opacity: 1; } }

.bars-wrap {
    width: 55%;
    z-index: 2;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    transition: transform 0.6s ease, left 0.6s ease;

    .chart.active & {
        left: 0;
        transform: translateX(0); }

    svg {
        width: 100%;
        height: auto; }

    .bar {
        opacity: 1;
        transition: opacity .4s ease;
        cursor: pointer; }

    &.active,
    &.hover {
        .bar {
            transition: opacity .4s ease;
            opacity: .2;

            &.active,
            &.hover,
            &:hover {
                opacity: 1; } } }

    .bars-legend {
        margin: 0;
        padding: 15px 0 0;
        list-style: none;
        overflow: hidden; }

    .legend-item {
        width: 50%;
        float: left;
        position: relative;
        padding: 5px 0px;
        opacity: 1;
        transition: opacity .4s ease;
        cursor: pointer; }

    .legend-color {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%); }

    .legend-name {
        padding-left: 25px; }


    &.active,
    &.hover {
        .legend-item {
            transition: opacity .4s ease;
            opacity: .2;

            &.active,
            &.hover,
            &:hover {
                opacity: 1; } } } }

.pie-wrap {
    position: relative;
    width: 45%;
    z-index: 1;
    opacity: 0;
    transition: opacity .6s ease .4s;

    .chart.active & {
        opacity: 1; }

    & > svg {
        position: relative;
        width: 58%;
        left: 50%;
        transform: translateX(-50%);
        height: auto; }

    .legend-item {
        opacity: 1;
        transition: opacity .4s ease;
        cursor: pointer; }

    .chart-title {
        padding-bottom: 1rem; }

    path {
        stroke: #fff;
        stroke-width: 2px;
        cursor: pointer;
        opacity: 1;
        transition: opacity .4s ease; }

    &.hover {
        .legend-item,
        path {
            opacity: .2;

            &.hover,
            &:hover {
                opacity: 1; } } } }

.pie-legend {
    width: 90%;
    margin-left: 5%;
    border-collapse: collapse;

    tbody,
    thead {
        border-bottom: 2px solid rgba(0,0,0,.1); }

    td {
        padding: 5px 0;

        &.legend-color {
            padding: 0;
            position: relative;


            svg {
                margin-top: 5px; } }

        &:first-child {
            width: 55px; }

        &:first-child,
        &:nth-child(3),
        &:nth-child(4) {
            text-align: center; } } }
