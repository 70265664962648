.smartForm {
    .fields-filter-link {
        cursor: pointer;
        text-decoration: underline;
        transition: color 0.4s ease, opacity 0.4s ease;
        opacity: 0.6;

        &.active,
        &:hover {
            color: #483bd4;
            opacity: 1; } } }

.textarea.ant-input {
    border-radius: .125rem;
    font-family: inherit; }


// Reseting antd styles for date picker
.antd-calendar {
    border-radius: 0;
    border: none;

    div:focus {
        outline: none !important; } }
.ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: none; }

.ant-input:hover, .ant-calendar-picker-input:hover {
    border-color: #483bd4; }

.ant-calendar-picker-input.ant-input {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: .125rem;
    background-color: #fafafb;
    box-sizing: border-box;
    line-height: 1.5;
    font-size: 1rem;
    margin-top: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 100%;
    outline: none;
    height: auto; }

.ant-input:focus {
    border-color: #483bd4;
    outline: 0;
    box-shadow: none;
    border-right-width: 1px !important; }

.ant-calendar-selected-date .ant-calendar-date, .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-selected-end-date .ant-calendar-date, .ant-calendar-date:active {
    background-color: #483bd4; }

.ant-calendar .ant-calendar-today-btn {
    color: #483bd4; }

.ant-calendar-today .ant-calendar-date, .ant-calendar-date:hover, .ant-calendar-selected-date.ant-calendar-date:hover, .ant-calendar-selected-start-date.ant-calendar-date:hover, .ant-calendar-selected-end-date .ant-calendar-date:hover {
    background-color:#fafafb {}
    border-color: #483bd4;
    color: #483bd4; }
