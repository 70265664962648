@import './constants.sass';
input {
    outline: none;
    transition: border-color 0.4s ease;

    &[type="submit"] {
        transition: border-color 0.4s ease,background-color 0.4s ease; } }

button {
    outline: none;
    transition: background-color 0.4s ease, border-color 0.4s ease; }

input[type=password],
input[type=text],
select,
textarea {
    background-color: rgba( 0, 0, 0, .01 ); }

.Toast__toast___2UNlJ {
    display: none; }


.btn-blue {
    transition: background-color 0.4s ease; }

.close-icon {
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: 21px;
    height: 21px;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 10px;
        width: 21px;
        height: 2px;
        background-color: rgba(0,0,0,0.2);
        transition: background-color 0.4s ease; }

    &:hover {
        &:before,
        &:after {
            background-color: #483bd4; } }

    &:before {
        transform: rotate(-45deg); }

    &:after {
        transform: rotate(45deg); } }

.main-content {
    max-width: 63rem;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
    display: flex;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    overflow: hidden; }

.scroll-y {
    overflow-y: auto; }

.ant-skeleton-paragraph {
    margin: 0;
    padding: 0; }

.ant-input-number-input-wrap {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        input[type=number] {
            -moz-appearance:textfield {} } } }

.h-act {
    transition: color 0.4s ease;
    &:hover {
        color: $activeColor; } }

.link-st {
    color: #1890ff;
    transition: color 0.4s ease;
    cursor: pointer;
    &:after {
        border: none !important; }
    &:hover {
        color: #40a9ff; } }

body {
    color: rgba(0, 0, 0, 0.85); }

.ant-select-selection--multiple {
    .ant-select-selection__placeholder {
        font-size: 1rem;
        margin-left: 3px;
        color: rgba(0, 0, 0, 0.55); } }

.ant-select-selection {
    background-color: rgba(0, 0, 0, 0.01);
    border: 1px solid rgba(0,0,0,.1);
    border-radius: .125rem; }

.ant-select-open .ant-select-selection,
.ant-select-selection:hover {
    border-color: #483bd4; }

.ant-select-dropdown-menu {
    padding: 0;
    margin: 0; }

.ant-select-dropdown.ant-select-dropdown--multiple {
    .ant-select-dropdown-menu-item {
        .ant-select-selected-icon {
            font-size: 16px !important;
            transform: translateY(-50%); } } }

.ant-select-search--inline .ant-select-search__field {
    padding-left: 0; }

.ant-dropdown {
    .ant-dropdown-menu {
        border-radius: 0;
        padding: 0;
        .anticon {
            font-size: inherit;
            color: inherit; } }
    .ant-dropdown-menu-item {
        font-size: 1rem;
        padding: 0.5rem 1rem; } }

.dropdown-confirmation {
    width: 130px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 0.8rem;

    .link-st {
        display: inline-block;
        padding: 0.8rem 0.8rem 0; } }

.ant-form-item {
    margin-bottom: 1rem; }
.ant-calendar-picker-input.ant-input {
    margin: 0;
    padding: 0.3rem; }

.ant-input-disabled {
    color: inherit; }
.ant-notification .offline {
    .ant-notification-notice-close {
        display: none; } }

.main-signed-in {
    padding: 0.5rem 1rem;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

body.bg {
    background-color: #0e0b2a; }

body.telia {
    background-color: #F2F2F2;

    .btn-ghost:hover,
    input[type=checkbox]:hover,
    input[type=password]:hover,
    input[type=radio]:hover,
    input[type=text]:hover,
    input[type=number]:hover,
    select:hover,
    textarea:hover,
    .ant-input:hover,
    .ant-input:active,
    .ant-input:focus,
    .ant-input-number:hover,
    .ant-input-number:active,
    .ant-input-number:focus,
    .ant-calendar-picker-input:hover,
    .ant-calendar-picker-input:active,
    .ant-calendar-picker-input:focus,
    .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
    .ant-select-open .ant-select-selection,
    .ant-select-selection:active,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
        border-color: $telActiveColor; }

    .btn-blue {
        background-color: $telActiveColor; }

    .btn-ghost,
    .blue-purple,
    .link,
    .link-st {
        color: $telActiveColor; }

    .bg-blue-purple {
        background-color: $telActiveColor; } }

.ant-form-item-required::after {
    display: inline-block;
    margin-left: 2px;
    color: #f5222d;
    font-size: 1.2rem;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: '*'; }

.ant-tooltip-inner a {
    color: #ffffff;
    font-weight: bold; }

.has-error {
    & + .error-message {
        color: #f5222d;
        font-size: 14px;
        padding: 0.4rem 0; }
    .ant-select-selection {
        border-color: #f5222d; } }
