$activeColor: #483bd4;
$selectColor: #52c41a;
$cl1: #4133c4;
$cl2: #F25F5C;
$cl3: #EBEBEB;
$cl4: #EFF1F3;
$cl5: #51CB20;
$cl6: #D9DBF1;
$cl7: lighten($cl6, 7%);
$telActiveColor: #990ae3;
