.file-picker {
    min-height: 300px;

    .Form__formContainer___Ht990,
    .Section__sectionBody___1n8qJ {
        margin: 0; }

    .Form__formSection___2Xr76 {
        box-shadow: none;
        padding: 10px 0;
        margin: 0; }

    .Button__button___3XjN7,
    .PhotoPicker__photoPlaceholderIcon___2YkBl {
        display: none; }

    .Section__sectionHeader___2fwDQ {
        position: absolute;
        width: 100%;
        text-align: center;
        padding: 0px;
        top: 50%;
        transform: translateY(-50%); }

    .PhotoPicker__photoPlaceholder___3uNzx {
        border: 3px solid rgba(0,0,0,0.1);
        transition: border-color 0.4s ease;
        height: 275px; }

    &:hover {
        .PhotoPicker__photoPlaceholder___3uNzx {
            border-color: #483bd4; } } }
