.hl-message-anim {
    position: relative;
    transform: translateY(65px);
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.4s ease;

    .show-hl-message & {
        opacity: 1;
        transform: translateY(60px); } }

.hello-msg-content {
    max-width: 52em; }

.hl-message-input-wrap {
    margin: 0 auto;
    max-width: 34em; }

.hl-message-step1 {
    .animate-hide & {
        transition-delay: 0.5s; } }

.hl-message-step2 {
    transition-delay: 0.1s;
    .animate-hide & {
        transition-delay: 0.6s; } }

.hl-message-step3 {
    transition-delay: 0.2s;
    .animate-hide & {
        transition-delay: 0.2s; } }

.hl-message-step4 {
    transition-delay: 0.3s;
    .animate-hide & {
        transition-delay: 0.1s; } }

.hl-message-step5 {
    transition-delay: 0.4s;
    .animate-hide & {
        transition-delay: 0s; } }

.animate-hide {
    .hl-message-step1,
    .hl-message-step2 {
        transform: translateY(0px); }

    .hl-message-step3,
    .hl-message-step4,
    .hl-message-step5 {
        transform: translateY(65px);
        opacity: 0; } }

.animate-done {
    .hl-message-step1,
    .hl-message-step2 {
        transform: translateY(0px);
        opacity: 1; }

    .hl-message-step3,
    .hl-message-step4,
    .hl-message-step5 {
        display: none; } }

.hl-message-step3 {
    input {
        background-color: rgba(250, 250, 250, 0.1); } }
